.flaticon-image {
  background-size: contain;
  width: 50px;
  height: 50px;
  filter: invert(19%) sepia(86%) saturate(3198%) hue-rotate(332deg) brightness(92%) contrast(90%);
}

.sidebar-link:hover .flaticon-image {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(14deg) brightness(103%) contrast(102%) !important;
}

.admin-icon {
  background-image: url(/public/assets/images/flaticons/admin.png);
}

.finance-icon {
  background-image: url(/public/assets/images/flaticons/bill.png);
}

.accounting-icon {
  background-image: url(/public/assets/images/flaticons/accounting.png);
}

.travel-icon {
  background-image: url(/public/assets/images/flaticons/travel2.png);
}