.react-dt .rdt_Table {
  height: 450px;
}

.react-dt .rdt_TableCell {
  color: rgb(54, 74, 99);
  min-height: 70px;
  position: relative;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
  font-size: 14px;
  font-family: "Noto Sans Arabic", sans-serif;
  white-space: unset;
  overflow: unset;
}

.react-dt .rdt_TableCell:before,
.react-dt .rdt_TableCell:after {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
  content: "";
  left: 0;
  right: 0;
  position: absolute;
  height: 1px;
  background: #007bff;
  width: 100%;
  opacity: 0;
  visibility: hidden;
}

.react-dt .rdt_TableCell:before {
  top: -1px;
}

.react-dt .rdt_TableCell:after {
  bottom: -1px;
}

.react-dt .rdt_TableRow:hover .rdt_TableCell {
  background: rgba(0, 123, 255, 0.03);
}

.react-dt .rdt_TableRow:hover .rdt_TableCell:before,
.react-dt .rdt_TableRow:hover .rdt_TableCell:after {
  opacity: 1;
  visibility: visible;
}

.react-dt .rdt_TableRow:hover .action-container {
  opacity: 1;
  cursor: pointer;
}

.react-dt .rdt_TableCol {
  background: #F5F6FA !important;
  color: #8096BD !important;
}